<template>
    <div class="modal" v-if="showModal">
		<div class="modal-content">
			<span class="close" @click="closeModal">&times;</span>
			<form id="api-key-form" v-if="activeView === 'api-keys'" @submit.prevent="submitApiKeysForm">
				<h1>New API Key</h1>
				<label>Name</label>
				<input type="text" id="api-key-name" v-model="apiKeyForm.name" required />

				<button id="api-key-submit" type="submit">Submit</button>
			</form>

			<form id="sdk-key-form" v-else-if="activeView === 'sdk-keys'" @submit.prevent="submitSdkKeysForm">
				<h1>New SDK Key</h1>
				<label>Name</label>
				<input id="sdk-key-name" type="text" v-model="sdkKeyForm.name" required />

				<label>Bundle ID</label>
				<input
					id="bundle-id"
					type="text"
					v-model="sdkKeyForm.bundle"
					:class="{ 'is-invalid': !isBundleIdValid }"
					@input="hasStartedTyping = true"
					required
				/>
				<div class="invalid-feedback" v-if="!isBundleIdValid && hasStartedTyping">
					Bundle ID must contain only alphanumeric characters, hyphens, and periods.
				</div>
				<button
					id="sdk-key-submit"
					type="submit"
					:disabled="!isBundleIdValid"
				>
					Submit
				</button>
			</form>
		</div>
	</div>
</template>

<script>
	import { ref, computed } from 'vue';
	import { useStore } from 'vuex';

	export default {
		name: 'DynamicFormModal',
		props: {
			showModal: Boolean,
			clickedItemLabel: String,
			emitFunction: Function,
		},
		setup(props) {
			const store = useStore();
			const activeView = computed(() => props.clickedItemLabel);
			const BUNDLE_ID_REGEX = /^[a-zA-Z0-9.-]+$/;
			const hasStartedTyping = ref(false);

			const apiKeyForm = ref({
				name: '',
			});

			const sdkKeyForm = ref({
				name: '',
				bundle: '',
			});

			const validateBundleId = (bundleId) => {
				return BUNDLE_ID_REGEX.test(bundleId);
			}

			const isBundleIdValid = computed(() => {
				return validateBundleId(sdkKeyForm.value.bundle);
			});

			const closeModal = () => {
				store.commit('SET_SHOW_DYNAMIC_MODAL', false);
			}

			const submitApiKeysForm = () => {
			// Handle form submission for API Keys form
				props.emitFunction('submit-api-keys-form', apiKeyForm.value);
				closeModal();
			}

			const submitSdkKeysForm = () => {
				if (isBundleIdValid.value) {
					props.emitFunction('submit-sdk-keys-form', sdkKeyForm.value);
					closeModal();
				}
			}

			return {
				activeView,
				apiKeyForm,
				sdkKeyForm,
				isBundleIdValid,
				hasStartedTyping,
				closeModal,
				submitApiKeysForm,
				submitSdkKeysForm,
			};
		},
	};
</script>


<style lang="scss" scoped>
	@import './CommonModalStyles.scss'
	@import './DynamicFormModal.scss'

</style>
