<template>
	<div class='dashboard-app'>
        <div class='app-content'>
        <div class='headline-container'>
            <h1>{{ viewDisplayNames[activeView] }}</h1>
            <div v-if="activeView != 'account'" class="search-container">
                <div class="input-container">
                    <font-awesome-icon icon="magnifying-glass" />
                    <input id="search-bar" v-model="searchTerm" @input="handleSearch" placeholder="Enter a key name or bundle ID">
                </div>
            </div>

            <span v-if="activeView != 'account'" id='create-button'>
            <button @click='showDynamicModalFunction(true)'>+ Add {{ viewDisplayNames[activeView] }}</button>
            </span>
        </div>

        <!-- API Keys and SDK Keys Table -->
        <div v-if="activeView != 'account'" class='table-container'>
            <KeysTable />
        </div>
        <div v-else-if="activeView == 'account'">
            <AccountPage />
        </div>
        <div class="pagination-container" v-if="totalPages > 1 && activeView !== 'account'">
            <div
                class="pagination-page"
                v-for="page in pageNumbers"
                @click="goToPage(page.pageNumber)"
                :key="page.pageNumber"
                :class="{ 'pagination-page-active': page.isActive }"
                >
                {{ page.pageNumber }}
            </div>
        </div>
        <DynamicFormModal
            v-if='showDynamicModal'
            :show-modal='showDynamicModal'
            :clicked-item-label='activeView'
            :emit-function='emitFormEvent'
            @close-modal='showDynamicModalFunction(false)'
        />
        <DeleteConfirmationModal
            v-if='showDeleteConfirmationModal'
            :itemData='deleteItemData'
            :show-modal='showDeleteConfirmationModal'
            @confirm-delete='handleDeleteConfirmation'
            @cancel-delete='closeDeleteConfirmationModal'
        />
        <ErrorModal
            v-if='showErrorModal'
            :show-modal='showErrorModal'
            :error-message='apiError'
            @on-close='closeErrorModal'
        />
        <LoadingModal
            v-if="showLoadingModal"
            :show-modal="showLoadingModal"
        />
        <KeyModal
            v-if='showKeyModal'
            :show-modal='showKeyModal'
            :key-value='keyValue'
            @on-close='closeKeyModal'
        />
        <SecretModal
            v-if='showSecretModal'
            :show-modal='showSecretModal'
            :secret-value='secretValue'
            @on-close='closeSecretModal'
        />
        </div>
    </div>

</template>


<script>
import { computed, watch, handleError, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useSCPRequest } from '../composables.js';
import { useAuth0 } from '@auth0/auth0-vue';

import AccountPage from './AccountPage.vue';
import KeysTable from './KeysTable.vue';

import DynamicFormModal from './modals/DynamicFormModal.vue';
import DeleteConfirmationModal from './modals/DeleteConfirmationModal.vue';
import ErrorModal from './modals/ErrorModal.vue';
import LoadingModal from './modals/LoadingModal.vue';
import KeyModal from './modals/KeyModal.vue';
import SecretModal from './modals/SecretModal.vue';

export default {
    name: 'DashboardApp',
    components: {
        AccountPage,
        KeysTable,
        DynamicFormModal,
        DeleteConfirmationModal,
        ErrorModal,
        LoadingModal,
        KeyModal,
        SecretModal
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const { makeSCPRequest } = useSCPRequest(store);
        const { getAccessTokenSilently } = useAuth0();

        const activeView = computed(() => route.params.activeView);
        const apiError = computed(() => store.state.errorMessage);
        const keyValue = computed(() => store.state.keyValue);
        const secretValue = computed(() => store.state.secretValue);
        const deleteItemData = computed(() => store.state.deleteItemData);
        const apiResponseObject = computed(() => store.state.apiResponseObject || []);
        const totalRows = computed(() => store.state.totalRows);
        const searchTerm = ref('');
        const currentPage = computed(() => store.state.currentPage);
        const permissions = computed(() => store.state.authentication.permissions || []);
        const allApiResponses = ref([]);
        const allSdkResponses = ref([]);
        const fullResponseObject = computed(() => store.state.fullResponseObject || []);

        const viewDisplayNames = {
            'account': 'Account',
            'api-keys': 'API Keys',
            'sdk-keys': 'SDK Keys',
         };

        const showDynamicModal = computed(() => store.state.showDynamicModal);
        const showDeleteConfirmationModal = computed(() => store.state.showDeleteConfirmationModal);
        const showErrorModal = computed(() => store.state.showErrorModal);
        const showLoadingModal = computed(() => store.state.showLoadingModal);
        const showKeyModal = computed(() => store.state.showKeyModal);
        const showSecretModal = computed(() => store.state.showSecretModal);

        onMounted(async () => {
            if (activeView.value !== 'account') {
            await fetchAllResponses();
            await fetchTableData();
            }
        });

        const emitFormEvent = async (eventType, formData) => {
        switch (eventType) {
            case 'submit-api-keys-form':
                try {
                    await handleApiKeysFormSubmit(formData);
                } catch(error) {
                    handleError(error);
					await fetchTableData();
                }
                break;
            case 'submit-sdk-keys-form':
                try {
                    await handleSdkKeysFormSubmit(formData);
                } catch(error) {
                    handleError(error);
					await fetchTableData();
                }
                break;
            }
        };

        //function that submits data to create new API Token
        const handleApiKeysFormSubmit = async (formData) => {
            const endpoint = '/v0.1/api_token';
            const data = {
                name: formData.name,
            };
            const params = {};

            const request = await makeSCPRequest(endpoint, 'post', data, params);
            store.commit('SET_KEY_VALUE', request);
            const response = fetchTableData();
            store.commit('SET_API_RESPONSE_OBJECT', response);
            store.commit('SET_SHOW_DYNAMIC_MODAL', false);
            store.commit('SET_SHOW_KEY_MODAL', true);
        }

        //function that submits data to create new SDK Key
        const handleSdkKeysFormSubmit = async (formData) => {
            const endpoint = '/v0.1/sdk/keys';
            const data = {
                name: formData.name,
                bundle_id: formData.bundle,
            };
            const params = {};

            const request = await makeSCPRequest(endpoint, 'post', data, params);
            store.commit('SET_KEY_VALUE', request);
            const response = fetchTableData();

            store.commit('SET_API_RESPONSE_OBJECT', response);
            store.commit('SET_SHOW_DYNAMIC_MODAL', false);
            store.commit('SET_SHOW_KEY_MODAL', true);
        }

        //function that populates the table
        const fetchTableData = async () => {
            findTotalResponseRows();
            let endpoint = '';
            let apiResponses;
            allApiResponses.value = [];
            allSdkResponses.value = [];
            store.commit('SET_FULL_RESPONSE_OBJECT', []);


            switch (activeView.value) {
                case 'api-keys':
                    endpoint = '/v0.1/api_token';
                    break;
                case 'sdk-keys':
                    endpoint = '/v0.1/sdk/keys';
                    break;
                default:
                    break;
            }

            const data = {};
            const params = {
                limit: '50',
            };

            try {
                store.commit('SET_SHOW_LOADING_MODAL', true);
                let offset = 0;

                do {
                    params.offset = offset.toString();
                    apiResponses = await makeSCPRequest(endpoint, 'get', data, params);

                    // Check if the response is empty
                    if (!apiResponses || apiResponses.length === 0) {
                        break;
                    }

                    // Update the appropriate array based on the activeView
                    if (activeView.value === 'api-keys') {
                        allApiResponses.value = [...allApiResponses.value, ...apiResponses];
                        store.commit('SET_FULL_RESPONSE_OBJECT', allApiResponses.value);
                    } else if (activeView.value === 'sdk-keys') {
                        allSdkResponses.value = [...allSdkResponses.value, ...apiResponses];
                        store.commit('SET_FULL_RESPONSE_OBJECT', allSdkResponses.value);
                    }

                    offset += parseInt(params.limit);
                } while (apiResponses && apiResponses.length > 0);

                // Set filteredResponses to the appropriate array based on activeView
                const filteredResponses = activeView.value === 'api-keys' ? allApiResponses.value : allSdkResponses.value;

                // Update the API response object with the sliced data
                store.commit('SET_API_RESPONSE_OBJECT', filteredResponses.slice(0, 25));
                store.commit('SET_SHOW_LOADING_MODAL', false);

            } catch (error) {
                handleApiError(error);
            }
        };

        //function that gets all responses from an endpoint
        const fetchAllResponses = async () => {
            try {

                if (permissions.value.includes('processing_access')) {
                    const apiResponses = await makeSCPRequest('/v0.1/api_token', 'get');
                    allApiResponses.value = apiResponses;
                }

                if (permissions.value.includes('sdk_access')) {
                    const sdkResponses = await makeSCPRequest('/v0.1/sdk/keys', 'get');
                    allSdkResponses.value = sdkResponses;
                }

                findTotalResponseRows(); // Update totalRows after fetching all responses
            } catch (error) {
                handleApiError(error);
            }
        };

        //function that filters based on search input
        const handleSearch = () => {
            const activeResponses = activeView.value === 'api-keys' ? allApiResponses.value : allSdkResponses.value;
            let filteredData;

            if (searchTerm.value) {
                filteredData = activeResponses.filter(item => {
            if (item && item.name && item.bundle_id) {
                return (
                    item.name.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                    item.bundle_id.toLowerCase().includes(searchTerm.value.toLowerCase())
                );
            }
            if (item?.name && !item?.bundle_id) {
                return (
                    item.name.toLowerCase().includes(searchTerm.value.toLowerCase())
                );
            }
            return false;
        });
            } else {
                filteredData = activeResponses;
            }

            store.commit('SET_API_RESPONSE_OBJECT', filteredData.slice(0, 25));
            store.commit('SET_TOTAL_ROWS', filteredData.length || 0);
        };


        //function that determines total number of table rows based on activeView
        const findTotalResponseRows = async () => {
            let endpoint = '';
            let offset = 0;
            const limit = 50;
			switch(activeView.value){
				case 'api-keys':
					endpoint = '/v0.1/api_token';
					break;
				case 'sdk-keys':
					endpoint = '/v0.1/sdk/keys';
					break;
				default:
					break;
			}
			const data = {};
			const params = {
				offset: offset.toString(),
				limit: limit.toString(),
			};
			try {
				let response = await makeSCPRequest(endpoint, 'get', data, params);
                if (response && Array.isArray(response) && response.length > 0) {
                    store.commit('SET_TOTAL_ROWS', response.length || 0);
                    if(response && response.length >= parseInt(limit)){
                        while (response && response.length >= parseInt(limit)) {
                            offset += parseInt(limit);
                            params.offset = offset.toString();

                            response = await makeSCPRequest(endpoint, 'get', data, params);
                            let tempRows = totalRows.value + response.length;
                            store.commit('SET_TOTAL_ROWS', tempRows);

                            if (response.length < parseInt(limit)) {
                                break;
                            }
                        }
                    }
                }
            } catch (error) {
				handleApiError(error);
			}
        }

        const totalPages = computed(() => Math.ceil(totalRows.value / 25));

        // Array of page numbers
        const pageNumbers = computed(() => {
            const pages = Array.from({ length: totalPages.value }, (_, index) => {
                let pageNumber = index + 1;
                const isActive = pageNumber === currentPage.value;
                return { pageNumber, isActive };
            });
            return pages;
        });

        // Function to update the current page and fetch data
        const goToPage = (pageNumber) => {
            store.commit('SET_CURRENT_PAGE', pageNumber);
            const activeResponses = activeView.value === 'api-keys' ? allApiResponses.value : allSdkResponses.value;

            const startIndex = (pageNumber - 1) * 25;
            const endIndex = pageNumber * 25;
            const slicedData = activeResponses.slice(startIndex, endIndex);
            store.commit('SET_API_RESPONSE_OBJECT', slicedData);

            // No need to update pageNumbers.value here

            // Adjust totalPages dynamically based on the updated totalRows
            store.commit('SET_TOTAL_PAGES', Math.ceil(store.state.totalRows / 25));

            if (pageNumber === totalPages.value && activeResponses.length % 25 !== 0) {
                const remainingItems = activeResponses.length % 25;
                store.commit('SET_API_RESPONSE_OBJECT', activeResponses.slice(startIndex, startIndex + remainingItems));
            }
        };



        //function that controls whether the dynamic modal is shown or not
        const showDynamicModalFunction = (value) => {
            store.commit('SET_SHOW_DYNAMIC_MODAL', value);
        }

        // Function to handle API request errors
        const handleApiError = async (error) => {
            if (error.response && error.response.status === 401) {
                const authData = {
                    accessToken: null,
                    username: '',
                    password: '',
                    superuserStatus: false,
                };
                store.commit('SET_AUTHENTICATION', authData);
                const loginData = {
                    loginEmail: '',
                    loginPassword: '',
                }
                store.commit('SET_LOGIN_FORM_DATA', loginData);
                store.commit('SET_IS_ACCESS_TOKEN_SET', false);
                store.commit('SET_ACCESS_TOKEN', null);
            } else {
                store.commit('SET_ERROR_MESSAGE', error.message || 'An error occurred.');
                store.commit('SET_SHOW_ERROR_MODAL', true);
            }
        }

        //function to close error modal
        const closeErrorModal = () => {
            store.commit('SET_SHOW_ERROR_MODAL', false);
            store.commit('SET_ERROR_MESSAGE', null);
        }

        //function to close key modal
        const closeKeyModal = () => {
            store.commit('SET_SHOW_KEY_MODAL', false);
            store.commit('SET_KEY_VALUE', null);
        }

        //function to close secret modal
        const closeSecretModal = () => {
            store.commit('SET_SHOW_SECRET_MODAL', false);
            store.commit('SET_SECRET_VALUE', null);
        }

        //function that deletes the API key
        const handleDeleteConfirmation = async () => {
            const tokenId = store.state.deleteItemData;
            deleteLineItem(tokenId);
            fetchTableData();
        }

        //function that specifically deletes an item
        const deleteLineItem = async (tokenId) => {
            let endpoint = '';
            switch(activeView.value){
                case 'api-keys':
                    endpoint = `/v0.1/api_token/${tokenId}`;
                    break;
                case 'sdk-keys':
                    endpoint = `/v0.1/sdk/keys/${tokenId}`;
                    break;
            }
            const data = {};
            const params = {};

            try {
                await makeSCPRequest(endpoint, 'delete', data, params);
                const response = await fetchTableData((currentPage.value - 1) * 25);
                store.commit('SET_API_RESPONSE_OBJECT', response);
                store.commit('SET_SHOW_DYNAMIC_MODAL', false);
            } catch (error) {
                handleApiError(error);
            }
        }

        //function called to request the delete modal to close
        const cancelDeleteConfirmation = () => {
            closeDeleteConfirmationModal();
        }

        //function that actually closes the delete modal and resets itemsToDelete to none
        const closeDeleteConfirmationModal = () => {
            store.commit('SET_SHOW_DELETE_CONFIRMATION_MODAL', false);
            store.commit('SET_DELETE_ITEM_DATA', null);
        }

        watch(() => activeView.value, async (newValue) => {
            if (newValue !== undefined && newValue !== 'account') {
                fetchTableData();
            }
        });

        watch(() => searchTerm.value, () => {
            handleSearch();
        });




        return {
            activeView,
            viewDisplayNames,
            apiError,
            makeSCPRequest,
            getAccessTokenSilently,
            keyValue,
            secretValue,
            apiResponseObject,
            showDynamicModal,
            showDynamicModalFunction,
            showDeleteConfirmationModal,
            showErrorModal,
            showLoadingModal,
            showKeyModal,
            showSecretModal,
            deleteItemData,
            emitFormEvent,
            handleApiKeysFormSubmit,
            handleSdkKeysFormSubmit,
            handleDeleteConfirmation,
            cancelDeleteConfirmation,
            deleteLineItem,
            closeDeleteConfirmationModal,
            closeErrorModal,
            closeKeyModal,
            closeSecretModal,
            fetchTableData,
            findTotalResponseRows,
            fetchAllResponses,
            totalRows,
            totalPages,
            pageNumbers,
            goToPage,
            handleSearch,
            searchTerm,
            fullResponseObject,
            store,
            route
        };
    }
};
  </script>

<style scoped lang="scss">
    @import './DashboardApp.scss'
</style>
