<template>
    <div class="logo-container">
      <img id="logo" alt="Structure logo" src="../assets/structure-logo.svg" @click="goBack" />
      <font-awesome-icon icon="xmark" />
    </div>
    <EulaText />
  </template>

  <script>
  import EulaText from './EulaText.vue';
  import { useRouter } from 'vue-router';

  export default {
    name: 'EulaPage',
    components: {
      EulaText,
    },
    setup() {
      const router = useRouter();

      const goBack = () => {
        router.back();
      };

      return {
        goBack,
        router,
      };
    },
  };
  </script>

  <style scoped lang="scss">
  @import './EulaPage.scss'
  </style>
