import axios from 'axios';
import { useStore } from 'vuex';
import { useAuth0 } from '@auth0/auth0-vue';
import { jwtDecode } from "jwt-decode";


export function useSCPRequest() {
	const store = useStore();
	const { getAccessTokenSilently, user, logout } = useAuth0();

	const handleLogout = () =>
    logout({
        logoutParams: {
        returnTo: process.env.VUE_APP_BASE_URL + '/login',
        }
    });

	const makeSCPRequest = async (endpoint, method, data = {}, params = {}) => {
	try {

		const token = await getAccessTokenSilently();
		const decoded = jwtDecode(token);
        const permissions = decoded.permissions;

        const roles = user.value['https://structure.io/roles'];
        store.commit('SET_AUTHENTICATION', { userIsAuthorized: true, roles: roles, permissions: permissions });
		const headers = { Authorization: 'Bearer ' + token };

		const config = {
			url: `${process.env.VUE_APP_SCP_BASE_URL + endpoint}`,
			method,
			data,
			params,
			headers,
		};

		const response = await axios(config);
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 401) {
			handleLogout();
		} else {
			error.message ? store.commit('SET_ERROR_MESSAGE', error.message) : store.commit('SET_ERROR_MESSAGE', 'An error occurred');
			store.commit('SET_SHOW_ERROR_MODAL', true);
			store.commit('SET_SHOW_LOADING_MODAL', false);
		}
	}
}

return { makeSCPRequest };
}
