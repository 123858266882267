<template>
    <div class="modal" v-if="showModal">
        <div class="modal-content">
                <span class="close" @click="closeKeyModal">&times;</span>
                <h2>Your Key</h2>
                <p>Your key can be found below:</p>
                <p v-if="keyValue">
                    <code>{{ keyValue.value }}</code>
                    <span id="copy-key" v-if="showCopyIcon" @click="copyToClipboard">
                        <font-awesome-icon icon="copy" id="copy-icon" />
                    </span>
                    <span v-if="showCheckIcon" id="check-key">
                        <font-awesome-icon icon="check" />
                    </span>
                </p>
                <template v-if="activeView != 'sdk-keys'">
                    <p><strong>This is the only time you will be able to see this.</strong> Please copy it and keep it in a safe place.</p>
                </template>
        </div>
    </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default {
    name: 'KeyModal',
    props: {
        showModal: Boolean,
        onClose: Function,
    },
    setup() {
        const store = useStore();
        const route = useRoute();

        const activeView = computed(() => route.params.activeView);
        const keyValue = computed(() => store.state.keyValue);
        const showCheckIcon = ref(false);
        const showCopyIcon = ref(true);

        const closeKeyModal = () => {
            store.commit('SET_SHOW_KEY_MODAL', false);
            store.commit('SET_KEY_VALUE', null);
        }

        const copyToClipboard = async () => {
            const textToCopy = keyValue.value.value;

            try {
                const textArea = document.createElement('textarea');
                textArea.value = textToCopy;
                document.body.appendChild(textArea);
                textArea.select();
                await navigator.clipboard.writeText(textToCopy);
                document.body.removeChild(textArea);
                showCheckIcon.value = true;
                showCopyIcon.value = false;
                setTimeout(() => {
                    showCheckIcon.value = false;
                    showCopyIcon.value = true;
                }, 3000);
            } catch (error) {
                console.error('Failed to copy text to clipboard:', error);
            }
        };


        return {
            keyValue,
            activeView,
            showCheckIcon,
            showCopyIcon,
            closeKeyModal,
            copyToClipboard,
        };
    },
};
</script>

<style lang="scss" scoped>

	@import './CommonModalStyles.scss'
	@import './KeyModal.scss'

</style>
