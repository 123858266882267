<template>
	<div id="app">
		<router-view></router-view>
        <FooterContainer />
	</div>
</template>

<script>

import FooterContainer from './components/FooterContainer.vue';

export default {
    name: 'App',
    components: {
    FooterContainer,
},
    setup() {

        return {
        };
    },
};
</script>

<style>
@font-face {
        font-family: Degular;
        src: url('https://store-4fwfwlzl1t.mybigcommerce.com/content/Degular/Degular%20%2814%29/Degular-Medium.otf');
        font-weight: 400;
    }

    @font-face {
        font-family: Degular;
        src: url('https://store-4fwfwlzl1t.mybigcommerce.com/content/Degular/Degular%20%2814%29/Degular-Semibold.otf');
        font-weight: 600;
    }

    @font-face {
        font-family: Degular;
        src: url('https://store-4fwfwlzl1t.mybigcommerce.com/content/Degular/Degular%20%2814%29/Degular-Light.otf');
        font-weight: 300;
    }
@import './assets/styles/variables.css';

* {
  box-sizing: border-box;
}

body {
      margin: 0;
      padding: 0;
    }
    #app {
        font-family: Degular, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        box-sizing: border-box;
        height: 100vh;
        width: 100%;
        background-image: url('./assets/home-bkg-dark.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
    }
</style>
